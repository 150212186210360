var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('b-row',{attrs:{"align-h":"center"}},[_c('div',{staticClass:"loader"})]):_c('div',[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',[_vm._v("Statistics")])]},proxy:true}])},[_c('b-row',[_c('statistic-card-horizontal',{attrs:{"icon":"UserCheckIcon","statistic":_vm.statistics.countFollowers,"statistic-title":"Followers"}}),_c('statistic-card-horizontal',{attrs:{"icon":"UserPlusIcon","statistic":_vm.statistics.countPrimaries,"statistic-title":"Primary"}}),_c('statistic-card-horizontal',{attrs:{"color":"warning","icon":"CalendarIcon","statistic":_vm.statistics.events_count,"statistic-title":"Events"}}),_c('statistic-card-horizontal',{attrs:{"color":"warning","icon":"UserCheckIcon","statistic":_vm.statistics.events_booked_count,"statistic-title":"Booked events"}}),_c('statistic-card-horizontal',{attrs:{"icon":"DollarSignIcon","color":"success","statistic":_vm.statistics.entityTrasctionsAmount.toFixed(0),"statistic-title":"Donations"}})],1)],1),_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',[_vm._v("Mobile User Data")])]},proxy:true}])},[_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Year"}},[_c('b-form-select',{attrs:{"options":_vm.yearsOptions},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)],1)],1),_c('chart',{attrs:{"height":250,"chartdata":_vm.chartdata,"options":{
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
        }}})],1),_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',[_vm._v("Donations Per Month")])]},proxy:true}])},[_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label":"Year"}},[_c('b-form-select',{attrs:{"options":_vm.yearsOptions},model:{value:(_vm.donationYear),callback:function ($$v) {_vm.donationYear=$$v},expression:"donationYear"}})],1)],1)],1),_c('chart',{attrs:{"height":250,"chartdata":_vm.donationData,"options":{
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }