<template>
  <div>
    <b-row
      v-if="isLoading"
      align-h="center"
    >
      <div
        class="loader"
      />
    </b-row>

    <div v-else>
      <b-card>
        <template #header>
          <h4>Statistics</h4>
        </template>
        <b-row>
          <statistic-card-horizontal
            icon="UserCheckIcon"
            :statistic="statistics.countFollowers"
            statistic-title="Followers"
          />
          <statistic-card-horizontal
            icon="UserPlusIcon"
            :statistic="statistics.countPrimaries"
            statistic-title="Primary"
          />
          <statistic-card-horizontal
            color="warning"
            icon="CalendarIcon"
            :statistic="statistics.events_count"
            statistic-title="Events"
          />
          <statistic-card-horizontal
            color="warning"
            icon="UserCheckIcon"
            :statistic="statistics.events_booked_count"
            statistic-title="Booked events"
          />
          <statistic-card-horizontal
            icon="DollarSignIcon"
            color="success"
            :statistic="statistics.entityTrasctionsAmount.toFixed(0)"
            statistic-title="Donations"
          />
        </b-row>
      </b-card>

      <b-card>
        <template #header>
          <h4>Mobile User Data</h4>
        </template>

        <b-row align-h="end">
          <b-col cols="3">
            <b-form-group label="Year">
              <b-form-select
                v-model="year"
                :options="yearsOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <chart
          :height="250"
          :chartdata="chartdata"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
          }"
        />
      </b-card>
      <b-card>
        <template #header>
          <h4>Donations Per Month</h4>
        </template>

        <b-row align-h="end">
          <b-col cols="3">
            <b-form-group label="Year">
              <b-form-select
                v-model="donationYear"
                :options="yearsOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <chart
          :height="250"
          :chartdata="donationData"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
          }"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import Chart from '@/common/components/Charts/BarChart.vue'
import StatisticCardHorizontal from '@/@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  name: 'Dashboard',
  components: {
    Chart,
    StatisticCardHorizontal,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      donationYear: new Date().getFullYear(),
      yearsOptions: [],
      xAxis: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      statistics: {},
      chartdata: {},
      donationData: {},
      isLoading: true,
    }
  },
  watch: {
    year() {
      this.getGraphData()
    },
    donationYear() {
      this.getDonationData()
    },
  },
  created() {
    this.getStatistics()
    this.getGraphData()
    this.getDonationData()
  },
  methods: {
    getStatistics() {
      this.$portalUsers.get('internalops/get-dashboard-statistics', {
        params: {
          user_type: 'inpo_admin',
          entity_id: this.$store.getters['mainEntity/getEntityId'],
        },
      }).then(res => {
        this.statistics = {
          countPrimaries: res.data.countPrimaries,
          countFollowers: res.data.countFollowers,
          events_count: res.data.events_count,
          events_booked_count: res.data.events_booked_count,
          entityTrasctionsAmount: res.data.entityTrasctionsAmount,
        }

        this.yearsOptions = res.data.years.reverse()
      }).catch(() => {
        this.statistics = {}
      })
    },
    getGraphData() {
      this.$portalUsers.post('/internalops/get-dashboard-graph', {
        year: this.year,
        user_type: 'inpo_admin',
        entity_id: this.$store.getters['mainEntity/getEntityId'],
      }).then(res => {
        this.chartdata = {
          labels: this.xAxis,
          datasets: [
            {
              label: 'Followers',
              data: res.data.data.users_followers_per_month.map(a => a.number),
              backgroundColor: '#27A6B0',
            },
            {
              label: 'Primary',
              data: res.data.data.users_primary_per_month.map(a => a.number),
              backgroundColor: '#ffcf82',
            },
          ],
        }
      }).catch(() => {
        this.chartdata = {}
      }).finally(() => {
        this.isLoading = false
      })
    },
    getDonationData() {
      this.$portalUsers.post('/internalops/get-dashboard-graph', {
        year: this.donationYear,
        user_type: 'inpo_admin',
        entity_id: this.$store.getters['mainEntity/getEntityId'],
      }).then(res => {
        this.donationData = {
          labels: this.xAxis,
          datasets: [
            {
              label: 'Donations',
              data: res.data.data.donation_per_month.map(donation => donation.number),
              backgroundColor: '#27A6B0',
            },
          ],
        }
      }).catch(() => {
        this.donationData = {}
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">

</style>
