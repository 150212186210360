<template>
  <div>
    <spinner-loader :loading="isLoading" />
    <div v-if="!isLoading">
      <b-card>
        <template #header>
          <h4>Statistics</h4>
        </template>
        <b-row>
          <statistic-card-horizontal
            color="warning"
            icon="CalendarIcon"
            :statistic="statistics.events_count"
            statistic-title="Events"
          />
          <statistic-card-horizontal
            color="warning"
            icon="UserCheckIcon"
            :statistic="statistics.events_booked_count"
            statistic-title="Booked events"
          />
          <statistic-card-horizontal
            icon="DollarSignIcon"
            color="success"
            :statistic="statistics.entityTrasctionsAmount.toFixed(0)"
            statistic-title="Donations"
          />
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import StatisticCardHorizontal from '@/@core/components/statistics-cards/StatisticCardHorizontal.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  name: 'Dashboard',
  components: {
    StatisticCardHorizontal,
    SpinnerLoader,
  },
  data() {
    return {
      statistics: {},
      isLoading: true,
    }
  },
  created() {
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      this.$portalUsers.get('internalops/get-dashboard-statistics', {
        params: {
          user_type: 'inpo_admin',
          entity_id: this.$store.getters['mainEntity/getEntityId'],
        },
      }).then(res => {
        this.statistics = {
          events_count: res.data.events_count,
          events_booked_count: res.data.events_booked_count,
          entityTrasctionsAmount: res.data.entityTrasctionsAmount,
        }
      }).finally(() => { this.isLoading = false })
    },
  },
}
</script>

<style lang="scss">

</style>
