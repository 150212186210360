<template>
  <div>
    <inpo-dashboard v-if="entityType === 'Islamic Org'" />
    <organizer-dashboard
      v-else-if="entityType === 'Event-Organization'"
    />
    <business-dashboard v-else />
  </div>
</template>
<script>
import BusinessDashboard from './businessDashboard.vue'
import InpoDashboard from './inpoDashboard.vue'
import OrganizerDashboard from './organizer-dashboard.vue'

export default {
  name: 'EntityDashboard',
  components: { OrganizerDashboard, InpoDashboard, BusinessDashboard },
  computed: {
    entityType() {
      return this.$store.getters['mainEntity/getEntityType']
    },
  },
}
</script>
<style lang="scss">

</style>
